/**
 * Calculate the current value required to meet the target rotation where the target rotation is between 0-360 degrees
 *
 * This is required to create a continuous rotation motion, by summing rotation values and maintaining the nearest actual rotation target
 *
 * @param current current rotation value
 * @param target rotation target between 0-1 radians
 */
export function progressiveRotation(current: number, target: number): number {
  const actual = current >= 0 ? current % (Math.PI * 2) : current % (Math.PI * 2) + (Math.PI * 2);
  const diff = target - actual;

  if (diff > Math.PI) {
    return current - actual + target - (Math.PI * 2);
  }

  if (diff < -Math.PI) {
    return current - actual + target + (Math.PI * 2);
  }

  return current - actual + target;
}

/**
 * Lock the current rotation to the nearest segment, if the difference is less than the threshold
 *
 * @param current
 * @param segments
 * @param threshold
 * @returns
 */
export function lockRotation(current: number, segments: number, threshold: number): number {
  const lockSegment = Math.PI * 2 / segments;
  const lockedRotation = Math.round(current / lockSegment) * lockSegment;

  if (Math.abs(current - lockedRotation) < threshold) {
    return lockedRotation;
  }

  return current;
}

interface ActiveRotationSegment {
  index: number | undefined;
  rotation: number;
}

export function thresholdActiveRotation(current: number, segments: number, threshold: number): ActiveRotationSegment {
  const lockSegment = Math.PI * 2 / segments;
  const lockedRotation = Math.round(current / lockSegment) * lockSegment;

  // Current rotation is outside of any segment's threshold
  if (Math.abs(current - lockedRotation) > threshold) {
    return {
      index: undefined,
      rotation: current,
    };
  }

  const indexRaw = Math.round(-lockedRotation / (Math.PI * 2 / segments)) % segments;
  const index = indexRaw < 0 ? indexRaw + segments : indexRaw;

  return {
    index,
    rotation: lockedRotation,
  };
}

interface SectorRotation {
  sector: number;
  rotation: number;
}

export function sectorRotation(
  segments: number,
  index: number,
  activeIndex?: number,
  activeSector: number = (Math.PI * 2) / segments * 1.5,
): SectorRotation {
  const sector = (Math.PI * 2) / segments;

  if (activeIndex === undefined) {
    return {
      sector,
      rotation: sector * index,
    };
  }

  const activeRotation = activeIndex * sector;

  const inactiveSector = (Math.PI * 2 - activeSector) / (segments - 1);

  // TODO: simplify these conditionals into a single calculation

  if (index < activeIndex) {
    return {
      sector: inactiveSector,
      rotation: activeRotation - (activeSector - inactiveSector) / 2 + (index - activeIndex) * inactiveSector,
    };
  }

  if (index > activeIndex) {
    return {
      sector: inactiveSector,
      rotation: activeRotation + (activeSector + inactiveSector) / 2 + (index - activeIndex - 1) * inactiveSector,
    };
  }

  return {
    sector: activeSector,
    rotation: activeRotation,
  };
}
