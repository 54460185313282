import type { SVGAttributes } from 'react';

import type { MenuItemBerry } from '../../types/configuration';
import TitleBlackberry from './svg/TitleBlackberry';
import TitleBlueberry from './svg/TitleBlueberry';
import TitleRaspberry from './svg/TitleRaspberry';
import TitleStrawberry from './svg/TitleStrawberry';

interface MenuItemContentBerryTitleProps extends SVGAttributes<SVGElement> {
  type: MenuItemBerry['type'];
}

export default function MenuItemContentBerryTitle({
  type,
  ...props
}: MenuItemContentBerryTitleProps) {
  switch (type) {
    case 'blackberry':
      return <TitleBlackberry {...props} />;
    case 'blueberry':
      return <TitleBlueberry {...props} />;
    case 'raspberry':
      return <TitleRaspberry {...props} />;
    case 'strawberry':
      return <TitleStrawberry {...props} />;
    default:
      return null;
  }
}
