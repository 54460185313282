import type React from 'react';
import {
  useSpring,
  animated,
  to,
} from '@react-spring/web';

const ROTATION_OFFSET = Math.PI / -2;
const HYPOTENUSE = 144;

const polygonStyle: React.SVGAttributes<SVGPolygonElement>['style'] = {
  vectorEffect: 'non-scaling-stroke',
};

interface MenuSliceProps {
  /** Center rotation of slice */
  rotation: number;
  /** "Width" of slice's sector */
  sector: number;
  /** Primary slice colour */
  color?: string;
  /** Stroke colour */
  stroke?: string;
  /** Stroke width */
  strokeWidth?: number;
}

export default function MenuSlice({
  rotation,
  sector,
  color = '#f8d849',
  stroke = undefined,
  strokeWidth = 8,
}: MenuSliceProps) {
  const spring = useSpring({
    to: {
      rotation,
      sector,
    },
  });

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMax slice"
    >
      <animated.polygon
        fill={color}
        stroke={stroke}
        strokeWidth={strokeWidth}
        style={polygonStyle}
        points={to(
          [spring.rotation, spring.sector],
          (rotation, sector) => {
            const angleA = rotation + ROTATION_OFFSET - sector / 2;
            const angleB = rotation + ROTATION_OFFSET + sector / 2;

            return (`
              50,100
              ${50 + Math.cos(angleA) * HYPOTENUSE},${100 + Math.sin(angleA) * HYPOTENUSE}
              ${
                /**
                 * Note that this is a bit of a hack and won't work if the sector is more than 180°
                 * or if the sector is more than 90° and the rotation is more than 90°
                 *
                 * Given more time, we'd probably want to calculate each point based on the sector
                 * and rotation, but this is a good enough approximation for now.
                 */
                sector > Math.PI / 2
                ? `0,0 100,0`
                : ''
              }
              ${50 + Math.cos(angleB) * HYPOTENUSE},${100 + Math.sin(angleB) * HYPOTENUSE}
            `);
          },
        )}
      />
    </svg>
  );
}
