import { MenuDebugPoint } from './MenuDebugPoint';
import type { DragRotationState } from './hooks/useDragRotation';

interface MenuDebugProps {
  enabled: boolean;
  rotationState: DragRotationState;
}

export default function MenuDebug({
  enabled,
  rotationState,
}: MenuDebugProps) {
  if (!enabled) {
    return null;
  }

  const {
    cursor,
    startCursor,
  } = rotationState;

  return (
    <>
      <MenuDebugPoint point={startCursor} color="blue" />
      <MenuDebugPoint point={cursor} color="red" />
    </>
  );
}
