import { MenuItemState } from '../types/items';

export function indexState(index: number, activeIndex?: number, openIndex?: number): MenuItemState {
  if (index === openIndex) {
    return 'open';
  }

  if (index === activeIndex) {
    return 'active';
  }

  return 'inactive';
}
