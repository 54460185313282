import type { DragRotationState } from './hooks/useDragRotation';
import type { Point } from './types/points';

interface MenuDebugPointProps {
  point?: Point;
  color?: string;
}

const DEBUG_POINT_SIZE = 10;

export function MenuDebugPoint({
  point,
  color = 'grey',
}: MenuDebugPointProps) {
  if (!point) {
    return null;
  }

  const {
    x,
    y,
  } = point;

  return (
    <div
      style={{
        position: 'absolute',
        width: DEBUG_POINT_SIZE,
        height: DEBUG_POINT_SIZE,
        top: y - DEBUG_POINT_SIZE / 2,
        left: x - DEBUG_POINT_SIZE / 2,
        backgroundColor: color,
        opacity: 0.5,
        borderRadius: '50%',
      }}
    />
  );
}
