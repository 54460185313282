import type {
  MenuConfiguration,
} from '../lib/types/configuration';

export const testMenuConfig: MenuConfiguration = {
  brand: {
    yellowDotPath: '/images/brand/yellow-dot.webp',
    bottomLogoPath: '/images/brand/driscolls-bottom.webp',
  },
  items: [
    {
      type: 'home',
      route: '/',
      background: '#f3f3f3',
      exploreColor: '#47703a',
    },
    {
      type: 'raspberry',
      route: '/raspberry',
      background: '#bd345c',
      berry: '/images/berries/raspberry.webp',
    },
    {
      type: 'blueberry',
      route: '/blueberry',
      background: '#2a6392',
      berry: '/images/berries/blueberry.webp',
    },
    {
      type: 'grow',
      route: '/how-we-story',
      background: '#4a3213',
      leafs: [
        '/images/leafs/raspberry-leaf.webp',
        '/images/leafs/raspberry-leaf.webp',
      ],
    },
    {
      type: 'sweetest',
      route: '/sweetest-batch',
      background: '#47703a',
      leafs: [
        '/images/leafs/blueberry-leaf.webp',
        '/images/leafs/strawberry-leaf.webp',
      ],
      berries: [
        '/images/berries/blueberry.webp',
        '/images/berries/strawberry.webp',
      ],
    },
    {
      type: 'hub',
      route: '/berry-hub',
      background: '#faf3e4',
      polaroids: [
        // Note these images must conform to `4:3.5` aspect ratio
        '/images/polaroids/greenhouse.jpg',
        '/images/polaroids/kitchen.jpg',
      ],
    },
    {
      type: 'blackberry',
      route: '/blackberry',
      background: '#5A3F7C',
      berry: '/images/berries/blackberry.webp',
    },
    {
      type: 'strawberry',
      route: '/strawberry',
      background: '#a42115',
      berry: '/images/berries/strawberry.webp',
    },
  ],
};
