import type React from 'react';
import { useId } from 'react';
import {
  animated,
  to,
  useSpring,
} from '@react-spring/web';

import {
  type MenuItemHub,
} from '../../types/configuration';
import { MenuItemContentBaseProps } from './types/items';
import TitleHub from './svg/TitleHub';
import { image } from './helpers/image';

const imageStyle: React.CSSProperties = {
  outline: '2px solid white',
  outlineOffset: '-2px',
};

/** Aspect ratio hard coded to 4:3.5 */
const polaroidHeight = `${100 * 3.5 / 4}%`;

interface MenuItemContentHubProps extends MenuItemContentBaseProps<MenuItemHub> {}

interface MenuItemStateProps {
  titleOpacity: number;
  titleScale: number;
  polaroidScale: number;
  polaroidARotation: number;
  polaroidBRotation: number;
}

function stateProps(state: string): MenuItemStateProps {
  switch (state) {
    case 'active':
      return {
        titleOpacity: 1,
        titleScale: .444,
        polaroidScale: .4,
        polaroidARotation: Math.PI / 15,
        polaroidBRotation: -Math.PI / 20,
      };
    case 'open':
      return {
        titleOpacity: 1,
        titleScale: .555,
        polaroidScale: .36,
        polaroidARotation: Math.PI / 12,
        polaroidBRotation: -Math.PI / 16,
      };
    case 'inactive':
    default:
      return {
        titleOpacity: 0,
        titleScale: 0,
        polaroidScale: .888,
        polaroidARotation: -Math.PI / 18,
        polaroidBRotation: Math.PI / 20,
      };
  }
}

export default function MenuItemContentHub({
  config: {
    polaroids: [
      polaroidA,
      polaroidB,
    ],
  },
  rotation,
  state = 'inactive',
}: MenuItemContentHubProps) {
  const titleGradientId = useId();

  const {
    titleScale,
    titleOpacity,
    polaroidScale,
    polaroidARotation,
    polaroidBRotation,
  } = stateProps(state);

  const wheelSpring = useSpring({
    to: {
      rotation,
    },
    config: {
      tension: 170 - 8,
      friction: 26 + 3,
    },
  });
  const menuStateSpring = useSpring({
    to: {
      titleOpacity,
      titleScale,
      polaroidScale,
      polaroidARotation,
      polaroidBRotation,
    },
    config: {
      tension: 170 - 4,
      friction: 26 + 1,
    },
  });

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <radialGradient
          id={titleGradientId}
          cx="30%"
          cy="40%"
        >
          <stop offset="30%" stopColor="#57744a" />
          <stop offset="95%" stopColor="#839f73" />
        </radialGradient>
      </defs>
      <animated.g
        will-change="style"
        transform="
          translate(0 -6)
          scale(.666)
        "
        style={{
          color: '#59774A',
          transformOrigin: '50% 50%',
          opacity: to(
            menuStateSpring.titleOpacity,
            (titleOpacity) => titleOpacity,
          ),
        }}
      >
        <TitleHub
          y="-16"
          textFill={`url(#${titleGradientId})`}
        />
      </animated.g>
      <animated.image
        will-change="transform"
        style={imageStyle}
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.polaroidScale,
            menuStateSpring.polaroidBRotation,
          ],
          (
            wheelRotation,
            polaroidScale,
            polaroidRotation,
          ) => {
            const translation = (1 - polaroidScale) * 50;

            return `
              translate(${translation * 1.71 - 20} ${translation * 2 - 10})
              rotate(${(wheelRotation + polaroidRotation) * 180 / Math.PI} 50 100)
              scale(${polaroidScale})
            `;
          },
        )}
        href={image(polaroidB)}
        width="100%"
        height={polaroidHeight}
        y="0"
        x="0"
      />
      <animated.image
        will-change="transform"
        style={imageStyle}
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.polaroidScale,
            menuStateSpring.polaroidARotation,
          ],
          (
            wheelRotation,
            polaroidScale,
            polaroidRotation,
          ) => {
            const translation = (1 - polaroidScale) * 50;

            return `
              translate(${translation * 0.3 + 20} ${translation * 2.28 - 10})
              rotate(${(wheelRotation + polaroidRotation) * 180 / Math.PI} 50 100)
              scale(${polaroidScale})
            `;
          },
        )}
        href={image(polaroidA)}
        width="100%"
        height={polaroidHeight}
        y="0"
        x="0"
      />
    </svg>
  );
}
