import {
  type MenuItemGrow,
} from '../../types/configuration';
import {
  animated,
  to,
  useSpring,
} from '@react-spring/web';
import { MenuItemContentBaseProps } from './types/items';
import TitleHowWeGrow from './svg/TitleHowWeGrow';
import { image } from './helpers/image';

interface MenuItemContentGrowProps extends MenuItemContentBaseProps<MenuItemGrow> {}

interface MenuItemStateProps {
  titleOpacity: number;
  titleScale: number;
  leafScale: number;
  leafProgress: number;
  leafARotation: number;
  leafBRotation: number;
}

function stateProps(state: string): MenuItemStateProps {
  switch (state) {
    case 'active':
      return {
        titleOpacity: 1,
        titleScale: .444,
        leafScale: .8,
        leafProgress: 1,
        leafARotation: -Math.PI / 3,
        leafBRotation: Math.PI / 3.6,
      };
    case 'open':
      return {
        titleOpacity: 1,
        titleScale: .555,
        leafScale: .9,
        leafProgress: 2,
        leafARotation: -Math.PI / 7,
        leafBRotation: Math.PI / 11,
      };
    case 'inactive':
    default:
      return {
        titleOpacity: 0,
        titleScale: 0,
        leafScale: 1.5,
        leafProgress: 0,
        leafARotation: -Math.PI / 4,
        leafBRotation: Math.PI / 6,
      };
  }
}

export default function MenuItemContentGrow({
  config: {
    leafs: [
      leafA,
      leafB,
    ],
  },
  rotation,
  state = 'inactive',
}: MenuItemContentGrowProps) {
  const {
    titleScale,
    titleOpacity,
    leafScale,
    leafProgress,
    leafARotation,
    leafBRotation,
  } = stateProps(state);

  const wheelSpring = useSpring({
    to: {
      rotation,
    },
    config: {
      tension: 170 - 8,
      friction: 26 + 3,
    },
  });
  const menuStateSpring = useSpring({
    to: {
      titleOpacity,
      titleScale,
      leafScale,
      leafProgress,
      leafARotation,
      leafBRotation,
    },
    config: {
      tension: 170 - 4,
      friction: 26 + 1,
    },
  });

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <animated.g
        will-change="style"
        transform="
          translate(0 -6)
          scale(.666)
        "
        style={{
          color: `#dfd9cd`,
          transformOrigin: '50% 50%',
          opacity: to(
            menuStateSpring.titleOpacity,
            (titleOpacity) => `${titleOpacity}`,
          ),
        }}
      >
        <TitleHowWeGrow
          y="-16"
        />
      </animated.g>
      <animated.image
        will-change="transform"
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.leafScale,
            menuStateSpring.leafProgress,
            menuStateSpring.leafBRotation,
          ],
          (
            wheelRotation,
            leafScale,
            leafProgress,
            leafRotation,
          ) => {
            const translation = (1 - leafScale) * 50;

            return `
            translate(${translation * .4 + 6} ${translation * 1.8})
            rotate(${(wheelRotation + leafRotation) * 180 / Math.PI} 50 100)
            translate(-42 48)
              scale(${leafScale})
            `;
          },
        )}
        href={image(leafB)}
        width="100%"
        height="100%"
        y="0"
        x="0"
      />
      <animated.image
        will-change="transform"
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.leafScale,
            menuStateSpring.leafARotation,
          ],
          (
            wheelRotation,
            leafScale,
            leafRotation,
          ) => {
            const translation = (1 - leafScale) * 50;

            return `
            translate(${translation * 1.3 + 15} ${translation * .7 + 10})
            rotate(${(wheelRotation + leafRotation) * 180 / Math.PI} 50 100)
            translate(40 30)
              scale(${leafScale})
            `;
          },
        )}
        href={image(leafA)}
        width="100%"
        height="100%"
        y="0"
        x="0"
      />
    </svg>
  );
}
