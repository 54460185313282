import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Point } from '../types/points';
import {
  lockRotation,
  progressiveRotation,
  sectorRotation,
  thresholdActiveRotation,
} from '../helpers/rotation';

export interface DragRotationState {
  dragging: boolean;
  rotation: number;
  startRotation?: number;
  cursor?: Point;
  startCursor?: Point;
  /** If a segment is at TDC / within threshold */
  activeIndex?: number;
  /** If a segment is / has been opened (it may also be active) */
  openIndex?: number;
  /** Callback to open or close a segment */
  setOpen: (index?: number) => void;
}

interface DragRotationProps {
  initialRotation?: number;
  lockOnRelease?: boolean;
  lockThreshold?: number;
  segments?: number;
  speed?: number;
}

export function useDragRotation(
  ref: React.RefObject<any>,
  {
    initialRotation = 0,
    segments = 8,
    lockThreshold = Math.PI / segments,
    lockOnRelease = true,
    speed = 1,
  }: DragRotationProps = {},
): DragRotationState {
  const startCursorRef = useRef<Point | undefined>(undefined);
  const rotationRef = useRef<number>(initialRotation);
  const rotationLiveRef = useRef<number>(initialRotation);
  const [
    cursor,
    setCursor,
  ] = useState<Point | undefined>(undefined);
  const [
    rotation,
    setRotation,
  ] = useState<number>(initialRotation);
  const [
    activeIndex,
    setActiveIndex,
  ] = useState<number | undefined>(undefined);
  const [
    openIndex,
    setOpenIndex,
  ] = useState<number | undefined>(undefined);

  const setOpen = useCallback(
    (index?: number) => {
      // Disable open state if no index is provided
      if (index === undefined) {
        setActiveIndex(openIndex);
        setOpenIndex(undefined);

        return;
      }

      // Set the open state to provided index
      setOpenIndex(index);

      // Auto-rotate to the selected segment
      const {
        rotation,
      } = sectorRotation(segments, index, index, Math.PI);

      rotationRef.current = progressiveRotation(rotationRef.current, -rotation);
      setRotation(rotationRef.current);

      // @todo Lock scrolling TBC
    },
    [],
  );

  const stopDrag = useCallback(
    () => {
      startCursorRef.current = undefined;
      setCursor(undefined);

      const {
        index,
        rotation,
      } = thresholdActiveRotation(rotationLiveRef.current, segments, lockThreshold);

      setActiveIndex(index);

      if (lockRotation !== undefined && lockOnRelease) {
        rotationRef.current = rotation;
        setRotation(rotationRef.current);
      } else {
        rotationRef.current = rotationLiveRef.current;
      }
    },
    [
      lockOnRelease,
    ],
  );

  const updateDrag = useCallback(
    (point: Point) => {
      setCursor(point);
      setActiveIndex(undefined);
      setOpenIndex(undefined);

      if (!startCursorRef.current) {
        startCursorRef.current = point;
      }
    },
    [],
  );

  useEffect(
    () => {
      const {
        current: container,
      } = ref;

      if (!container) {
        return;
      }

      const handleMouseDown = ({
        clientX,
        clientY,
      }: React.MouseEvent) => {
        updateDrag({
          x: clientX,
          y: clientY,
        });
      }

      const handleMouseMove = ({
        clientX,
        clientY,
      }: React.MouseEvent) => {
        if (startCursorRef.current) {
          const {
            x: startX,
            y: startY,
          } = startCursorRef.current;
          const {
            current: rotationStart,
          } = rotationRef;

          const deltaX = clientX - startX;
          const deltaY = clientY - startY;
          const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
          const direction = deltaX / Math.abs(deltaX);

          rotationLiveRef.current = rotationStart + deltaX / (speed * Math.PI * 100);

          setRotation(() => rotationLiveRef.current);

          updateDrag({
            x: clientX,
            y: clientY,
          });
        }
      }

      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('mouseup', stopDrag);

      return () => {
        stopDrag();

        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseup', stopDrag);
      };
    },
    [
      ref,
    ],
  );

  useEffect(
    () => {
      window.addEventListener('mouseup', stopDrag);

      return () => {
        window.removeEventListener('mouseup', stopDrag);
      }
    },
    [],
  )

  return {
    activeIndex,
    cursor,
    openIndex,
    rotation,
    setOpen,
    dragging: !!startCursorRef.current,
    startCursor: startCursorRef.current,
    startRotation: rotationRef.current,
  };
}

export function usePreventDrag(ref: React.RefObject<any>) {
  useEffect(
    () => {
      const button = ref.current;

      if (button) {
        const stopPropagation = (event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
        }

        button.addEventListener('mousedown', stopPropagation);
        button.addEventListener('mousemove', stopPropagation);

        return () => {
          button.removeEventListener('mousedown', stopPropagation);
          button.removeEventListener('mousemove', stopPropagation);
        };
      }
    },
    [
      ref,
    ],
  );
}
