import type React from 'react';
import {
  useCallback,
  useRef,
} from 'react';

import type { MenuConfiguration } from '../types/configuration';
import { useDragRotation } from './private/hooks/useDragRotation';
import { indexState } from './private/helpers/indexes';
import { sectorRotation } from './private/helpers/rotation';
import MenuDebug from './private/MenuDebug';
import MenuItem from './private/MenuItem';
import MenuItemExplore from './private/MenuItemExplore';

const containerStyle: React.HTMLAttributes<HTMLElement>['style'] = {
  backgroundColor: 'white',
  width: '100%',
  minHeight: '100lvh',
  height: '100vh',
  userSelect: 'none',
};

const centerStyle: React.HTMLAttributes<HTMLElement>['style'] = {
  display: 'block',
  position: 'absolute',
  left: 0,
  right: 0,
  margin: 'auto',
};

const logoHeight = 'min(40vw, 200px)';

const logoStyle: React.HTMLAttributes<HTMLElement>['style'] = {
  ...centerStyle,
  width: 'auto',
  height: logoHeight,
  top: 'calc(100vh - min(40vw, 200px))',
  userSelect: 'none',
};

const exploreContainerStyle: React.HTMLAttributes<HTMLElement>['style'] = {
  ...centerStyle,
  bottom: `calc(${logoHeight} * 0.77)`,
  width: '100%',
  textAlign: 'center',
};

const svgStyle: React.SVGAttributes<SVGSVGElement>['style'] = {
  width: '100%',
  height: '100%',
};

export interface DialMenuProps {
  menu: MenuConfiguration;
  activeSector?: number;
  debug?: boolean;
};

export default function DialMenu({
  debug,
  menu: {
    items,
    brand: {
      yellowDotPath,
      bottomLogoPath,
    },
  },
  activeSector,
}: DialMenuProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const rotationState = useDragRotation(containerRef);
  const {
    openIndex,
    setOpen,
    activeIndex = openIndex,
    rotation: wheelRotation,
  } = rotationState;

  const adjustedActiveSector = openIndex !== undefined ? Math.PI : activeSector;
  const activeConfig = activeIndex !== undefined ? items[activeIndex] : undefined;

  const exploreClick = useCallback(
    () => {
      setOpen(activeIndex);
    },
    [
      setOpen,
      activeIndex,
    ],
  );

  const menuItems = items.map((config, index) => {
    const {
      sector,
      rotation,
    } = sectorRotation(items.length, index, activeIndex, adjustedActiveSector);

    return (
      <MenuItem
        config={config}
        key={index}
        rotation={wheelRotation + rotation}
        sector={sector}
        state={indexState(index, activeIndex, openIndex)}
      />
    );
  });

  return (
    <div style={containerStyle} ref={containerRef}>
      {/* Quick / temporary solution for bottom-mounted logo */}
      <img src={bottomLogoPath} style={logoStyle} />
      <MenuDebug enabled={!!debug} rotationState={rotationState} />
      <svg style={svgStyle}>
        {menuItems}
      </svg>
      <div style={exploreContainerStyle}>
        <MenuItemExplore
          config={activeConfig}
          enabled={!!activeConfig && openIndex === undefined}
          onClick={exploreClick}
        />
      </div>
    </div>
  );
}
