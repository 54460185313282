import type {
  SVGAttributes,
} from 'react';

interface ExploreArrowProps extends SVGAttributes<SVGElement> {
  color?: string;
}

export default function ExploreArrow({
  color = 'currentColor',
  ...props
}: ExploreArrowProps) {
  return (
    <svg
      viewBox="0 0 58 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M55.4241 7.47C54.3041 7.876 53.4501 7.89 53.0161 7.89H52.8901C53.0021 10.172 53.5061 12.244 54.3881 12.244C55.6761 12.244 55.4941 11.628 56.2081 11.628C56.6001 11.628 56.7681 11.88 56.7681 12.174C56.7681 12.342 56.7121 12.538 56.6141 12.692C56.3621 13.084 55.7881 13.35 54.5981 13.35C50.4261 13.35 49.9081 10.172 49.9081 7.47C49.9081 6.28 50.2581 2.948 51.4621 1.94C51.4621 1.576 51.5461 1.492 51.8121 1.492C53.1841 1.492 54.5561 1.702 56.1381 1.912C56.6701 1.982 56.7821 2.15 56.7961 2.416V2.43C56.7961 2.976 56.2221 3.144 56.2221 3.144C56.2221 3.144 55.3821 3.424 53.2541 3.48C53.0161 4.292 52.8901 5.384 52.8761 6.504H52.9461C53.7861 6.504 55.0041 6.546 55.4381 6.714C55.5641 6.756 55.6341 6.91 55.6341 7.078C55.6341 7.246 55.5641 7.414 55.4241 7.47Z"
      />
      <path
        fill={color}
        d="M44.5842 6.994C44.6542 7.106 44.7802 7.246 45.4942 7.246C46.2082 7.246 46.5442 6.14 46.5442 4.95C46.5442 3.55 46.0822 2.038 45.2562 2.038C44.7942 2.038 44.5842 2.15 44.3742 2.262C44.5282 3.298 44.5842 4.936 44.5842 6.994ZM45.3682 1.156C47.5102 1.156 48.5602 3.256 48.5602 4.81C48.5602 6.014 48.3642 7.358 47.0062 7.918C47.7062 8.87 48.5742 10.998 49.1482 11.74C49.4282 12.09 49.5122 12.356 49.5122 12.566C49.5122 13.042 48.8402 13.322 48.4622 13.322C48.0702 13.322 47.5382 13.056 46.9782 11.936C46.2502 10.522 45.7602 8.954 45.5502 8.184H45.3822C45.0602 8.184 44.7942 8.17 44.5702 8.128C44.5282 10.83 44.4022 13.28 44.4022 13.28C44.4022 13.28 43.9822 13.616 43.4082 13.616C42.0082 13.616 41.8402 12.132 41.8402 11.11C41.8402 10.158 41.9382 8.828 41.9662 6.966C42.0642 1.786 42.2042 1.156 43.9262 1.156C43.9822 1.156 44.0382 1.198 44.0802 1.254C44.4162 1.198 44.8502 1.156 45.3682 1.156Z"
      />
      <path
        fill={color}
        d="M36.5891 12.454C37.4431 12.454 38.0731 10.62 38.0731 5.832C38.0731 2.948 37.3871 2.374 36.8271 2.374C35.9591 2.374 34.9091 2.738 34.9091 9.136C34.9091 10.116 35.1051 12.454 36.5891 12.454ZM32.1231 9.346C32.1231 3.382 33.8731 1.254 36.3091 1.254C37.0371 1.254 40.8731 1.436 40.8731 5.3C40.8731 9.262 39.8931 11.278 38.9691 12.286C38.1431 13.21 36.8831 13.588 35.5811 13.588C34.0411 13.588 32.1231 12.888 32.1231 9.346Z"
      />
      <path
        fill={color}
        d="M26.08 0.903999C26.556 0.903999 27.032 1.044 27.368 1.562C27.76 2.164 28.04 4.488 28.04 6.028C28.04 6.588 28.124 8.254 28.124 9.906C28.124 10.69 28.11 11.474 28.054 12.132C28.558 12.104 29.188 12.062 29.818 12.062C31.008 12.062 32.142 12.188 32.142 12.664C32.142 13.644 31.736 13.938 27.648 13.938C25.52 13.938 25.548 12.09 25.534 10.186C25.52 8.562 25.436 6.392 25.436 4.558C25.436 2.528 25.562 0.903999 26.08 0.903999Z"
      />
      <path
        fill={color}
        d="M20.0658 4.18C20.0518 4.236 20.0238 4.376 19.8558 4.474V7.218C20.4018 7.246 20.3598 7.428 21.0458 7.428C21.7318 7.428 22.1238 5.734 22.1238 4.222C22.1238 3.018 21.8718 1.926 21.3258 1.926C19.8838 1.926 20.2198 3.438 20.0658 4.18ZM20.6958 8.562C20.3458 8.562 20.0658 8.492 19.8558 8.366V12.594C19.8558 13.546 19.7298 14.008 18.5678 14.008C17.4338 14.008 17.2378 12.972 17.2378 10.998C17.2378 9.122 17.5038 6.784 17.5038 5.216C17.5038 4.74 17.4478 4.278 17.4478 3.578C17.4478 1.618 18.0358 0.848 18.6938 0.848C19.2398 0.848 19.6178 1.072 19.7578 1.184C20.1078 0.96 20.5558 0.82 21.1578 0.82C23.1038 0.82 24.5178 1.674 24.5178 3.928C24.5178 5.664 23.8878 8.562 20.6958 8.562Z"
      />
      <path
        fill={color}
        d="M9.16988 1.618C9.32388 1.562 9.53388 1.506 9.81388 1.506C9.98188 1.506 10.1919 1.534 10.4159 1.576C11.0039 1.702 11.9839 3.494 12.9919 5.51C13.6079 4.488 14.3079 3.298 15.2039 1.758C15.4559 1.324 15.7079 1.198 15.9039 1.198C16.1279 1.198 16.3519 1.366 16.3519 1.632C16.3519 1.87 16.2119 2.122 16.2119 2.122C16.2119 2.122 15.4699 3.816 13.7619 7.036C14.5319 8.59 15.2739 10.06 15.8479 10.802C16.2819 11.376 16.4359 11.852 16.4359 12.188C16.4359 12.79 15.8059 13.112 15.2179 13.112C14.5599 13.112 13.5659 12.608 12.3059 10.228C12.2499 10.13 12.1939 10.032 12.1519 9.934C10.5279 12.706 9.65988 13.588 9.11388 13.588C9.02988 13.588 8.95988 13.574 8.88988 13.532C8.63788 13.406 8.51188 13.308 8.51188 13.14C8.51188 12.888 8.79188 12.454 9.37988 11.446C9.92588 10.508 10.3039 9.948 11.3259 8.282C10.0799 5.608 9.18388 3.102 8.88988 2.206C8.87588 2.15 8.86188 2.108 8.86188 2.052C8.86188 1.856 8.98788 1.688 9.16988 1.618Z"
      />
      <path
        fill={color}
        d="M6.42248 7.47C5.30248 7.876 4.44848 7.89 4.01448 7.89H3.88848C4.00048 10.172 4.50448 12.244 5.38649 12.244C6.67449 12.244 6.49248 11.628 7.20648 11.628C7.59848 11.628 7.76649 11.88 7.76649 12.174C7.76649 12.342 7.71048 12.538 7.61248 12.692C7.36048 13.084 6.78648 13.35 5.59648 13.35C1.42448 13.35 0.906485 10.172 0.906485 7.47C0.906485 6.28 1.25648 2.948 2.46048 1.94C2.46048 1.576 2.54448 1.492 2.81048 1.492C4.18248 1.492 5.55449 1.702 7.13649 1.912C7.66848 1.982 7.78048 2.15 7.79448 2.416V2.43C7.79448 2.976 7.22049 3.144 7.22049 3.144C7.22049 3.144 6.38049 3.424 4.25248 3.48C4.01448 4.292 3.88848 5.384 3.87448 6.504H3.94448C4.78448 6.504 6.00248 6.546 6.43648 6.714C6.56248 6.756 6.63248 6.91 6.63248 7.078C6.63248 7.246 6.56248 7.414 6.42248 7.47Z"
      />
      <path
        fill={color}
        d="M29.2157 66.9796C28.7715 66.1982 29.437 67.3662 29.2157 66.9796C29.6195 66.0415 30.1392 64.9113 30.4712 63.9405C30.7712 63.0663 30.9684 62.1565 31.161 61.2505C31.2881 60.6567 31.672 60.3655 32.2369 60.4212C32.917 60.4865 33.273 61.0422 33.0347 61.6636C32.1342 64.0027 31.217 66.3345 30.239 68.6438C30.1169 68.9338 29.5908 69.2341 29.2589 69.2246C28.8948 69.2151 28.4113 68.9325 28.204 68.627C27.3451 67.3662 26.6115 66.0273 25.7481 64.7703C25.2351 64.0234 24.5679 63.3712 23.9677 62.6781C23.7664 62.445 23.4601 62.2419 23.385 61.9801C23.3016 61.6828 23.3547 61.2999 23.4933 61.0193C23.5503 60.9038 24.0291 60.8654 24.2353 60.9592C24.6021 61.123 24.9346 61.3854 25.2299 61.6576C25.9233 62.3033 26.8533 64.6775 27.5345 65.3471C27.6157 64.8304 27.5253 62.5094 27.5345 61.8812C27.6406 54.6602 27.7426 47.437 27.7974 40.2147C27.8162 37.5813 27.7085 34.9477 27.6437 32.3129C27.6372 32.0206 27.545 31.7268 27.4717 31.4384C27.3013 30.7675 27.5433 30.3498 28.1956 30.218C28.7676 30.1015 29.2954 30.4918 29.3069 31.1317C29.3412 32.9589 29.3153 34.7863 29.3387 36.6149C29.3672 38.9205 29.4366 41.2248 29.4587 43.5301C29.4719 44.9264 29.4315 46.3233 29.4211 47.7201C29.4056 49.5194 29.4096 51.3179 29.3835 53.1164C29.3584 54.8349 29.3055 56.5516 29.2654 58.2691C29.2442 59.1453 29.2485 60.0252 29.1932 60.8991C29.1276 61.8956 29.4223 62.9566 29.2589 63.9405C29.2589 64.7703 29.4058 65.504 29.4058 66.542L29.2157 66.9796Z"
      />
    </svg>
  );
}
