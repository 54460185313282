import type React from 'react';
import { useId } from 'react';

import type { MenuItemContentBaseProps } from './types/items';
import MenuSlice from './MenuSlice';
import MenuItemContent from './MenuItemContent';

interface MenuItemProps extends MenuItemContentBaseProps {
  /** "Width" of slice's sector */
  sector?: number;
}

export default function MenuItem({
  config,
  state,
  rotation,
  sector = Math.PI / 4,
}: MenuItemProps) {
  const clipId = useId();
  const {
    background,
  } = config;

  return (
    <>
      <defs>
        <mask id={clipId}>
          <MenuSlice
            color="#fff"
            stroke="black"
            rotation={rotation}
            sector={sector}
          />
        </mask>
      </defs>
      <MenuSlice
        color={background}
        stroke="white"
        rotation={rotation}
        sector={sector}
      />
      <g mask={`url(#${clipId})`}>
        <MenuItemContent
          config={config}
          rotation={rotation}
          state={state}
        />
      </g>
    </>
  );
}
