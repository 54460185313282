import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './private/reportWebVitals';
import { DialMenu } from './lib';
import { testMenuConfig } from './config/menuItems';

// We're using create-react-app to host a test-bed for our component.
// This is a simple way to test our component in isolation, without
// having to build a full application.
//
// The component consumer (i.e. Wordpress site) will need to render
// the component in a similar way.
//
const root = ReactDOM.createRoot(
  document.getElementById('dial-menu') as HTMLElement
);
root.render(
  <React.StrictMode>
    <DialMenu
      menu={testMenuConfig}
      debug={process.env.NODE_ENV === 'development'}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
