import type React from 'react';
import { useId } from 'react';
import {
  animated,
  to,
  useSpring,
} from '@react-spring/web';

import {
  type MenuItemHome,
} from '../../types/configuration';
import { MenuItemContentBaseProps } from './types/items';
import TitleLifeTastesSweeter from './svg/TitleLifeTastesSweeter';

interface MenuItemContentHomeProps extends MenuItemContentBaseProps<MenuItemHome> {}

interface MenuItemStateProps {
  titleOpacity: number;
  titleScale: number;
  polaroidScale: number;
  polaroidARotation: number;
  polaroidBRotation: number;
}

function stateProps(state: string): MenuItemStateProps {
  switch (state) {
    case 'active':
      return {
        titleOpacity: 1,
        titleScale: .444,
        polaroidScale: .4,
        polaroidARotation: Math.PI / 15,
        polaroidBRotation: -Math.PI / 20,
      };
    case 'open':
      return {
        titleOpacity: 1,
        titleScale: 1,
        polaroidScale: 1,
        polaroidARotation: Math.PI / 30,
        polaroidBRotation: -Math.PI / 7,
      };
    case 'inactive':
    default:
      return {
        titleOpacity: 0,
        titleScale: 0,
        polaroidScale: .888,
        polaroidARotation: -Math.PI / 18,
        polaroidBRotation: Math.PI / 20,
      };
  }
}

export default function MenuItemContentHome({
  config: {
  },
  rotation,
  state = 'inactive',
}: MenuItemContentHomeProps) {
  const titleGradientId = useId();

  const {
    titleScale,
    titleOpacity,
    polaroidScale,
    polaroidARotation,
    polaroidBRotation,
  } = stateProps(state);

  const wheelSpring = useSpring({
    to: {
      rotation,
    },
    config: {
      tension: 170 - 8,
      friction: 26 + 3,
    },
  });
  const menuStateSpring = useSpring({
    to: {
      titleOpacity,
      titleScale,
      polaroidScale,
      polaroidARotation,
      polaroidBRotation,
    },
    config: {
      tension: 170 - 4,
      friction: 26 + 1,
    },
  });

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <radialGradient
          id={titleGradientId}
          cx="40%"
          cy="20%"
        >
          <stop offset="50%" stopColor="#57744a" />
          <stop offset="98%" stopColor="#839f73" />
        </radialGradient>
      </defs>
      <animated.g
        will-change="style"
        transform="
          translate(0 -6)
          scale(.666)
        "
        style={{
          color: '#59774A',
          transformOrigin: '50% 50%',
          // opacity: to(
          //   menuStateSpring.titleOpacity,
          //   (titleOpacity) => titleOpacity,
          // ),
        }}
      >
        <TitleLifeTastesSweeter
          y="-16"
          textFill={`url(#${titleGradientId})`}
        />
      </animated.g>
    </svg>
  );
}
