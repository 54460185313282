export interface ImageSet {
  small?: string;
  medium: string;
  large: string;
}

export type Image = string | ImageSet;

export function isImageSet(image: Image): image is ImageSet {
  return (
    (image as ImageSet).medium !== undefined
    && (image as ImageSet).large !== undefined
  );
}

export type MenuItemBerryType = (
  | 'blueberry'
  | 'strawberry'
  | 'raspberry'
  | 'blackberry'
);

export type MenuItemPageType = (
  | 'home'
  | 'grow'
  | 'hub'
  | 'sweetest'
);

export type MenuItemType = MenuItemBerryType | MenuItemPageType;

export interface MenuItemBase<T extends MenuItemType = MenuItemType> {
  /** Menu items are pre-determined due to hard-coded graphics */
  type: T;
  /** Default route can be overridden */
  route?: string;
  /** Primary colour background */
  background?: string;
  /** Explore prompt colour (defaults to white) */
  exploreColor?: string;
}

export interface MenuItemBerry extends MenuItemBase<MenuItemBerryType> {
  berry: Image;
}

export interface MenuItemHome extends MenuItemBase<'home'> {}

export interface MenuItemHub extends MenuItemBase<'hub'> {
  /**
   * Note these images must conform to `4:3.5` aspect ratio
   *
   * This is required to define the frame in the svg, if variable aspect ratios
   * are required, the config and svg rendering will need to be updated
   */
  polaroids: [Image, Image];
}

export interface MenuItemGrow extends MenuItemBase<'grow'> {
  leafs: [Image, Image];
}

export interface MenuItemSweetest extends MenuItemBase<'sweetest'> {
  leafs: [Image, Image];
  berries: [Image, Image];
}

export type MenuItemDetail = (
  | MenuItemBerry
  | MenuItemHome
  | MenuItemHub
  | MenuItemGrow
  | MenuItemSweetest
);

/**
 * Supported menu item types
 *
 * @todo Omit `MenuItemDetail['type']` from `MenuItemBase` to enforce required
 * details prior to publishing
 */
export type MenuItem = MenuItemDetail;

export type MenuItems = MenuItem[];

export interface MenuConfiguration {
  /** Menu items */
  items: MenuItems;
  brand: {
    bottomLogoPath: string;
    yellowDotPath: string;
  }
}

/**
 * Type guard for `MenuItemBerry`
 *
 * @param item
 * @returns
 */
export function isMenuItemBerry(
  item: MenuItem,
): item is MenuItemBerry {
  return (item as MenuItemBerry).berry !== undefined;
}
