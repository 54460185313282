import {
  type Image,
  isImageSet,
} from '../../../types/configuration';

export function image(image: Image): string {
  if (isImageSet(image)) {
    return image.medium;
  }

  return image;
}
