import {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import Axios from 'axios';

import type { MenuItemContentBaseProps } from './types/items';
import ExploreArrow from './svg/ExploreArrow';
import { usePreventDrag } from './hooks/useDragRotation';

const buttonStyle: React.CSSProperties = {
  background: 'none',
  border: 'none',
  width: 'min(20vw, 20vh, 100px)',
  cursor: 'pointer',
};

interface MenuItemExploreProps extends Partial<Pick<MenuItemContentBaseProps, 'config'>> {
  onClick?: () => any;
  enabled?: boolean;
}

export default function MenuItemExplore({
  config,
  onClick,
  enabled = false,
}: MenuItemExploreProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  usePreventDrag(buttonRef);

  const {
    route = undefined,
    exploreColor = enabled ? 'white' : 'transparent',
  } = config || {};

  const transition = enabled ? 'color .9s' : 'color 0.3s';
  const pointerEvents = enabled ? 'auto' : 'none';
  const animation = document.querySelector("#dial-menu");

  const clickAction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (route) {
      onClick?.();

      const post_data = JSON.stringify({ url: route });

      Axios.post(`https://driscoll.kgkrunch.com/api.php`, post_data, {
        headers: {
          accept: "application/json",
        },
      }).then((res) => {
        const parser = new DOMParser();
        const htmlDocument = parser.parseFromString(
          res.data.response,
          "text/html"
        );
        // Use querySelector on the parsed DOM structure
        const mainElement = htmlDocument.querySelector("body");

        if (mainElement) {

          const container = document.querySelector("body");
          const dialmenu = mainElement.querySelector("#dial-menu");
          const dialmenuinner = mainElement.querySelector("#page");

          if (dialmenu && animation) {
            dialmenu.replaceWith(animation);
          }

          if (container) {
            
            container.replaceWith(mainElement);
            (dialmenuinner && dialmenuinner.classList.add('kg-animation-load'))
            
            
            console.log("Replacement successful");
            var script = document.createElement("script");
            script.src = "/wp-content/themes/driscoll/js/kg_custom.js?ver="+Math.random() ;
            document.head.appendChild(script);  
  
            
          } else {
            console.error("Container element not found in the DOM");
          }
        } else {
          console.log("Main element not found");
        }
      });


      // @todo Update if / as required for WordPress build
      window.history.pushState({}, '', route);
    }
  };

  return (
    <button
      ref={buttonRef}
      onClick={clickAction}
      style={{
        ...buttonStyle,
        transition,
        pointerEvents,
        color: exploreColor,
      }}
    >
      <ExploreArrow
        width="100%"
        height="100%"
      />
    </button>
  );
}
